import { createSlice } from '@reduxjs/toolkit';

const refreshSlice = createSlice({
  name: 'refresh',
  initialState: {
    trigger: false,
  },
  reducers: {
    triggerRefresh: (state) => {
      state.trigger = !state.trigger; // toggles the flag
    },
  },
});

export const { triggerRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;
